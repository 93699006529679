import { handleSiteModal } from "./util/modalHelperFunctions";

document.addEventListener("DOMContentLoaded", () => {
  const parentContainer = document.querySelector("body");
  if (!parentContainer) return;

  parentContainer.addEventListener("click", (event) => {
    const modalOpen = event.target.closest("[data-modal-open]");
    const modalClose = event.target.closest("[data-modal-close]");

    if (modalOpen) {
      event.preventDefault();
      const openModal = document.querySelector(`[data-modal="${modalOpen.getAttribute("data-modal-open")}`);

      if (openModal) {
        handleSiteModal(openModal, "open");

        // deal with specific needs when search is clicked
        if (event.target.matches("#search_button") || event.target.closest("#search_button")) {
                
          // auto focus in search field
          document.getElementById("search-field").focus?.();
  
          // close menu if open
          document.querySelector(".page-header .menu-wrapper")?.classList.remove("is-visible");
          document.getElementById('hamburger-icon')?.classList.remove('open');
        }
      }

    }

    if (modalClose) {
      event.preventDefault();
      const closeModal = document.querySelector(`[data-modal="${modalClose.getAttribute("data-modal-close")}`);

      if (closeModal) {
        handleSiteModal(closeModal, "close");

        // close video if open in modal
        const modalVideo = closeModal.querySelector("iframe");
        if (modalVideo) {
          let iframeSrc = modalVideo.src;
          modalVideo.src = iframeSrc;
        }

        // if wait container surrounds submit button, 
        // reset status unless proceed button is clicked
        const btnGroup = document.querySelector(".btn-group");
        if (btnGroup && !event.target.hasAttribute('data-modal-proceed')) {
          btnGroup?.classList.remove("wait");
        }

      }
    }
  });

});



